
.checkbox-cell {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.checkbox-cell .ag-cell-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-theme-quartz { // or your theme name
  /* AG Grid theme customization */
  --ag-font-size: 12px;
  --ag-font-family: 'Amazon Ember', Helvetica, Arial, sans-serif;
  --ag-header-font-size: 12px;
  --ag-header-font-weight: 600;
  --ag-header-background-color: #eaeff4;
  --ag-header-foreground-color: black;
  height: calc(100vh - 250px);

  /* Header styles */
  .ag-header-cell {
    font-size: var(--ag-header-font-size);
    font-weight: var(--ag-header-font-weight);
    background-color: var(--ag-header-background-color);
    color: var(--ag-header-foreground-color);
  }

  .ag-cell-wrapper {
    height: 100%;
    width: 100%;
  }

  .ag-input-field-input {
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
