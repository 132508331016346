.header {
  padding: initial;
  background-color: #005bb5;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.title,
.category,
.description {
  color: white;
}

.landing-header {
  width: 100%;
  background-image: url('../../images/LandingPgBackground.png');
  background-color: #17202B;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 10px 0;
}

.landing-header * {
  color: #ffffff !important;
}