.filter-display {
    display: flex;
    align-items: center;
  }
  
  .filter-text {
    &__column {
      color: #0972d3; // blue
    }
  
    &__operator {
      color: #cea008; // orange
    }
  
    &__value {
      color: #1D8102; // green
    }
  }
  
  .filter-more-link {
    color: #0972d3;
    cursor: pointer;
    text-decoration: none;
  
    &:hover {
      text-decoration: underline;
    }
  }
  
  .filter-container {
    display: inline-block;
  }