.appLayout {
 position: relative;
 top: 56px;
}

.topNavigation {
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 z-index: 1002;
 border-bottom: 1px solid #424650;
}